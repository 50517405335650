@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Thin.eot");
  src: local("Moderat Thin"), local("Moderat-Thin"),
    url("../public/fonts/Moderat-Thin.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Moderat-Thin.woff2") format("woff2"),
    url("../public/fonts/Moderat-Thin.woff") format("woff"),
    url("../public/fonts/Moderat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Thin-Italic.eot");
  src: local("Moderat Thin Italic"), local("Moderat-Thin-Italic"),
    url("../public/fonts/Moderat-Thin-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Moderat-Thin-Italic.woff2") format("woff2"),
    url("../public/fonts/Moderat-Thin-Italic.woff") format("woff"),
    url("../public/fonts/Moderat-Thin-Italic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Light.eot");
  src: local("Moderat Light"), local("Moderat-Light"),
    url("../public/fonts/Moderat-Light.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Moderat-Light.woff2") format("woff2"),
    url("../public/fonts/Moderat-Light.woff") format("woff"),
    url("../public/fonts/Moderat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Light-Italic.eot");
  src: local("Moderat Light Italic"), local("Moderat-Light-Italic"),
    url("../public/fonts/Moderat-Light-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Moderat-Light-Italic.woff2") format("woff2"),
    url("../public/fonts/Moderat-Light-Italic.woff") format("woff"),
    url("../public/fonts/Moderat-Light-Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Regular.eot");
  src: local("Moderat Regular"), local("Moderat-Regular"),
    url("../public/fonts/Moderat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Moderat-Regular.woff2") format("woff2"),
    url("../public/fonts/Moderat-Regular.woff") format("woff"),
    url("../public/fonts/Moderat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Regular-Italic.eot");
  src: local("Moderat Regular Italic"), local("Moderat-Regular-Italic"),
    url("../public/fonts/Moderat-Regular-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Moderat-Regular-Italic.woff2") format("woff2"),
    url("../public/fonts/Moderat-Regular-Italic.woff") format("woff"),
    url("../public/fonts/Moderat-Regular-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Medium.eot");
  src: local("Moderat Medium"), local("Moderat-Medium"),
    url("../public/fonts/Moderat-Medium.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Moderat-Medium.woff2") format("woff2"),
    url("../public/fonts/Moderat-Medium.woff") format("woff"),
    url("../public/fonts/Moderat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Medium-Italic.eot");
  src: local("Moderat Medium Italic"), local("Moderat-Medium-Italic"),
    url("../public/fonts/Moderat-Medium-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Moderat-Medium-Italic.woff2") format("woff2"),
    url("../public/fonts/Moderat-Medium-Italic.woff") format("woff"),
    url("../public/fonts/Moderat-Medium-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Bold.eot");
  src: local("Moderat Bold"), local("Moderat-Bold"),
    url("../public/fonts/Moderat-Bold.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Moderat-Bold.woff2") format("woff2"),
    url("../public/fonts/Moderat-Bold.woff") format("woff"),
    url("../public/fonts/Moderat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Bold-Italic.eot");
  src: local("Moderat Bold Italic"), local("Moderat-Bold-Italic"),
    url("../public/fonts/Moderat-Bold-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Moderat-Bold-Italic.woff2") format("woff2"),
    url("../public/fonts/Moderat-Bold-Italic.woff") format("woff"),
    url("../public/fonts/Moderat-Bold-Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Black.eot");
  src: local("Moderat Black"), local("Moderat-Black"),
    url("../public/fonts/Moderat-Black.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Moderat-Black.woff2") format("woff2"),
    url("../public/fonts/Moderat-Black.woff") format("woff"),
    url("../public/fonts/Moderat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Moderat";
  src: url("../public/fonts/Moderat-Black-Italic.eot");
  src: local("Moderat Black Italic"), local("Moderat-Black-Italic"),
    url("../public/fonts/Moderat-Black-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Moderat-Black-Italic.woff2") format("woff2"),
    url("../public/fonts/Moderat-Black-Italic.woff") format("woff"),
    url("../public/fonts/Moderat-Black-Italic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Serif+4:opsz,wght@8..60,200;8..60,300;8..60,400;8..60,500;8..60,600;8..60,700;8..60,800;8..60,900&display=swap");

:root {
  --primary-color-50: #ffede5;
  --primary-color-100: #ffddcc;
  --primary-color-300: #ffbb99;
  --primary-color-400: #ffaa80;
  --primary-color-500: #ff9866;
  --primary-color-600: #ff874d;
  --primary-color-700: #ff7633;
  --primary-color-900: #ff5400;
  --stage-overlay: linear-gradient(86.24deg, #005465 7.69%, #3bd8f8 150.53%);
  --stage-overlay-disabled: linear-gradient(86.24deg, #005465 7.69%, #3bd8f8 150.53%);
  --preview-stage-overlay: linear-gradient(
    86.24deg,
    #005465 7.69%,
    #3bd8f8 150.53%
  );
  --preview-stage-solid: #3bd8f8;
  --stage-solid: #3bd8f8;
  --stage-disabled: #F8F8F8;
  --header: #FFFFFF;
}
body {
  font-family: "Moderat", "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background: #f0f0f0;
}

.font-serif4 {
  font-family: "Source Serif 4", serif;
}

h1,
.h1 {
  font-size: 3rem;
  line-height: 1;
  font-weight: 600;
}

h1.medium,
.h1.medium {
  font-size: 3.75rem;
}

h1.large,
.h1.large {
  font-size: 4.5rem;
}

h2,
.h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 600;
}

h3,
.h3 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
}

h6,
.h6 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #14142b;
}

@media (min-width: 1536px) {
  .container {
    max-width: 1344px;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #d8d8d8;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button {
  outline: none;
}

/* input number arrow remove */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* date adding calender */
input[type="date"] {
  position: relative;
  appearance: none;
  background: url("../public/images/icons/calendar.svg") calc(100% - 15px) 13px
    no-repeat;
}

input[type="time"] {
  position: relative;
  appearance: none;
  background: url("../public/images/icons/time-circle-outline-gray.svg")
    calc(100% - 15px) 15px no-repeat;
  background-size: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.datepicker[type="date"] {
  background: url("../public/images/icons/calendar.svg") 10px 13px no-repeat;
}

.datepicker[type="date"]::-webkit-datetime-edit-text {
  -webkit-appearance: none;
  display: none;
}

.datepicker[type="date"]::-webkit-datetime-edit-month-field {
  -webkit-appearance: none;
  display: none;
}

.datepicker[type="date"]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none;
  display: none;
}

.datepicker[type="date"]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none;
  display: none;
}

/* tooltip */
.tooltip {
  position: absolute;
}

.tooltip::after {
  position: absolute;
  content: "";
}

.tooltip.top {
  bottom: calc(100% + 5px);
}

.tooltip.top::after {
  left: calc(50% - 8px);
  bottom: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #1f1f37;
}

.tooltip.bottom {
  top: calc(100% + 5px);
}

.tooltip.bottom::after {
  left: calc(50% - 8px);
  top: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #1f1f37;
}

.tooltip.left {
  top: 0;
  right: calc(100% + 10px);
}

.tooltip.left::after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #1f1f37;
  right: -8px;
  top: 8px;
}

.tooltip.right {
  top: 0;
  left: calc(100% + 10px);
}

.tooltip.right::after {
  left: -8px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #1f1f37;
}

.tooltip.center {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.center::after {
  left: calc(50% - 8px);
}

.tooltip .content {
  display: inline-block;
}

/* select-dropdown */
.select-dropdown svg {
  color: #4b4b63;
  margin-right: 12px;
}

.select-dropdown .select__value-container {
  padding: 0 3px 0 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.select-dropdown.medium-dropdown .select__value-container {
  padding: 19px 16px;
}

.select-dropdown
  .select__control.select__control--is-focused
  .select__indicator {
  transform: none;
}

.select-dropdown
  .select__control.select__control--is-focused.select__control--menu-is-open
  .select__indicator
  svg {
  transform: rotate(180deg);
}

.select-dropdown .select__control {
  box-shadow: none;
  border: 1px solid #e5e5eb;
  border-radius: 3px;
  background: #fcfcfc;
  color: #14142b;
  padding: 11px 0;
  border-bottom: 1px solid #9c9caf;
}

.select-dropdown.rounded-none .select__control {
  border-radius: 0;
}

.select-dropdown.border-error .select__control {
  padding: 10px 0;
}

.select-dropdown:hover .select__control {
  border: 1px solid #e5e5eb;
  border-bottom: 1px solid #9c9caf;
}

.select-dropdown.no-b-outline .select__control {
  border-bottom: 1px solid #e5e5eb;
}

.select-dropdown.no-b-outline:hover .select__control {
  border-bottom: 1px solid #e5e5eb;
}

.select-dropdown .select__placeholder {
  color: #9c9caf;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.select-dropdown .select__input-container {
  padding: 0;
  margin: 0;
}

.select-dropdown .select__indicator {
  padding: 0;
}

.select-dropdown .css-1okebmr-indicatorSeparator {
  display: none;
}

.select-dropdown .select__menu {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.select-dropdown .select__menu > option {
  padding: 16px;
}

.select-dropdown .select__menu div {
  color: #1f1f37 !important;
}

.select-dropdown .select__multi-value__label {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1f1f37;
  padding: 4px 0 4px 8px !important;
}

.select-dropdown .select__multi-value {
  background: #e5e5eb;
  border-radius: 4px;
}

.select-dropdown .select__multi-value__remove:hover {
  background: transparent;
}

.select-dropdown .select__multi-value__remove svg {
  margin-right: 0;
  margin-top: 2px;
  color: #373751;
}

/* badge-group */
.badge-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px -20px;
}

.badge-group span {
  margin: 0 5px 20px;
}

/* login-bg */
@media screen and (min-width: 1367px) {
  .login-bg span {
    width: 100% !important;
  }
}

/* tp-form Editor */
.tp-form .rdw-editor-main {
  height: 224px;
  margin-top: -5px;
  margin-bottom: 24px;
  border: 1px solid #e5e5eb;
  word-break: break-all;
  padding: 0 16px;
}

/* szh-menu  */
.szh-menu {
  border: none !important;
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.11));
  border-radius: 4px;
  background: #fcfcfc;
  min-width: 160px;
  z-index: 1;
}

.szh-menu .szh-menu__item {
  cursor: pointer;
}

.szh-menu__item--hover {
  background: #f0f0f0 !important;
}

/* hover-trigger  */

.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-source {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: flex;
}

.rdw-link-modal {
  left: auto !important;
  right: 0 !important;
}

/* line-clamp */
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Editor */
.tp-form .rdw-editor-main {
  min-height: 222px;
  margin-top: -6px;
  margin-bottom: 24px;
  border: 1px solid #e5e5eb;
  word-break: break-all;
  padding: 0 16px;
}

.rdw-link-decorator-wrapper,
.welcome-content a {
  color: #3891ff;
  text-decoration: underline;
}

.rdw-list-dropdown,
.rdw-text-align-dropdown {
  z-index: 1 !important;
}

.rdw-image-modal-upload-option-label {
  word-break: break-word;
}

/*  */
.noErrorMsg .text-error {
  display: none;
}

/* datepicker */
.react-datepicker__header {
  background: transparent !important;
  border: none !important;
  padding-bottom: 0 !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: rgb(255 152 102 / 10%) !important;
}

.react-datepicker__day--in-range {
  background: rgb(255 152 102 / 10%) !important;
  border-radius: 4px;
  color: #14142b !important;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range),
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background: #ff9866 !important;
  color: #fff !important;
}

.react-datepicker__month {
  margin: 8px 16px 16px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #ff9866 !important;
  font-weight: 400 !important;
  text-align: left;
  padding: 16px;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #fff !important;
}

.react-datepicker__navigation {
  top: 25px !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #4b4b63 !important;
  border-width: 2px 2px 0 0 !important;
  width: 9px;
}

.react-datepicker__navigation--previous {
  right: 30px;
  left: auto !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-left: -16px;
}

/*filter-applied */
.filter-applied {
  position: relative;
}

.filter-applied::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background: #e64c4c;
  margin-left: -27px;
  margin-top: -11px;
  border-radius: 50%;
  border: 1px solid #fff;
}

/* circle-progress */
.circle-progress {
}

.fill-none {
  fill: none;
}

.meter {
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-dasharray: 430;
  animation: progress 0.5s ease-out;
}

@keyframes progress {
  from {
    stroke-dashoffset: 360;
  }

  to {
    stroke-dashoffset: 250;
  }
}
/*!*WelcomeContent*!*/
/*.welcome-content p,.welcome-content div {*/
/*  white-space: normal !important;*/
/*}*/

.welcome-content p {
  margin-bottom: 15px;
}

/* dataTable */
.dataTable th > div,
.dataTable td > div {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 0.75rem;
}

.dataTable td > div {
  font-size: 1rem;
}

.dataTable th:first-child > div,
.dataTable td:first-child > div {
  padding-left: 2rem;
}

@media screen and (min-width: 1440px) {
  .dataTable th > div,
  .dataTable td > div {
    padding-right: 2rem;
  }
}

.custom-datepicker-calendar .react-datepicker__day--selected {
  background-color: var(--primary-color-600);
}
.custom-datepicker-calendar .react-datepicker__day--selected:hover {
  background-color: #4d8793;
}
/* 
.eng-circle {
  stroke: white;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-dasharray: calc((2 * 3.14) * 40);
   stroke-dashoffset: calc((2 * 3.14 * 45) * (1 - calc(var(--progress) / 100))); 
  transform-origin: 50% 50%;
  transform: rotate(105deg);
} */

.bg {
  
  background: conic-gradient(from 180deg, #4FBAF0,#F2D152, #F44336 );
  width: 100%;
  height: 100%;
}

.eng-bg{
  background: conic-gradient(from 182deg, #4FBAF0,#4FBAF0,#4FBAF0,#12AB0F, #FFF176,#FFF176,#FF6D00,#FF6D00, #F44336,#F44336,#F44336);
  height: 100%;
}
.bg-gray{
  background: gray;
  width: 100%;
  height: 100%;
}
.list {
  padding: 0; 
  width: 224px; 
  margin-left: -12px;
  margin-top: -10px;
  margin-bottom: -10px;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 8px 36px 8px 12px;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.list-item:hover {
  background-color: 'bg-gray-50';
}

.list-item.parent.selected {
  color: #ff874d; 
  background-color: var(--primary-color-50); 
}

.list-item .icon {
  margin-right: 14px;
}

.list-item .text {
  flex-grow: 1;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.arrow-icon {
  margin-left: auto;
}

.collapse {
  display: none;
}

.collapse.open {
  display: block;
}

.collapse .list-item {
  padding-left: 48px;
  font-size: medium;
}

.collapse .list-item.selected {
  background-color: transparent;
  color: #ff874d; 
  font-weight: 500;
}